import React from 'react'
import './index.scss'
import { graphql } from 'gatsby'
import SEO from '@components/seo'
import { SanityPressArticlePage } from '@utils/types'
import { Container } from 'react-bootstrap'
import { BaseBlockContent } from '@utils/richText'
import { GatsbyImage } from 'gatsby-plugin-image'
import ButtonComponent from '@components/buttonComponent'
import useResizeWidth from '@hooks/useResizeWidth'
import { FadeInSection } from '@components/Animations'

type Props = {
  data: {
    sanityPressArticlePage: SanityPressArticlePage
    allSanityPressArticlePage: {
      edges: Array<{ node: SanityPressArticlePage }>
    }
  }
  location: Location
}

const PressArticlePage = ({
  data: {
    sanityPressArticlePage,
    allSanityPressArticlePage: { edges },
  },
  location,
}: Props) => {
  const { width } = useResizeWidth()
  const metadata = {
    location: location?.href,
    ...sanityPressArticlePage?.seo,
  }

  const renderPrevNext = () => {
    const index = edges.findIndex(
      ({ node }) => node.slug.current === sanityPressArticlePage.slug.current,
    )
    const next = index === edges.length - 1 ? 0 : index + 1
    const prev = index === 0 ? edges.length - 1 : index - 1
    return (
      <>
        {prev >= 0 && prev !== index && (
          <ButtonComponent
            button={{
              title:
                width > 760
                  ? `< Previous: ${edges[prev]?.node?.title}`
                  : 'PREV',
              slug: { current: `/press${edges[prev]?.node?.slug?.current}` },
              type: 'primary',
            }}
            className="pa-btn"
          />
        )}
        {next >= 0 && prev !== index && (
          <ButtonComponent
            button={{
              title:
                width > 760 ? `Next: ${edges[next]?.node?.title} >` : 'NEXT',
              slug: { current: `/press${edges[next]?.node?.slug?.current}` },
              type: 'primary',
            }}
            className="pa-btn"
          />
        )}
      </>
    )
  }

  return (
    <>
      <SEO {...metadata} />
      <section className="pressArt-container">
        <Container>
          <FadeInSection timeout={250}>
            <p className="breadcrumbs">
              Press / {sanityPressArticlePage?.title}
            </p>
            <h1 className="ivyprestoheadlinethin">
              {sanityPressArticlePage?.title}
            </h1>
            {sanityPressArticlePage?._rawSubtitle && (
              <BaseBlockContent
                blocks={sanityPressArticlePage?._rawSubtitle}
                className="pa-subtitle"
              />
            )}
          </FadeInSection>

          {sanityPressArticlePage?._rawContent && (
            <FadeInSection timeout={250}>
              <BaseBlockContent
                blocks={sanityPressArticlePage?._rawContent}
                className="pa-content"
              />
            </FadeInSection>
          )}

          {sanityPressArticlePage?.image?.asset && (
            <FadeInSection timeout={250}>
              <GatsbyImage
                image={sanityPressArticlePage?.image?.asset?.gatsbyImageData}
                alt={sanityPressArticlePage?.image?.alt}
                objectFit="cover"
              />
            </FadeInSection>
          )}
          {sanityPressArticlePage?.magazine?.asset && (
            <FadeInSection timeout={250}>
              <GatsbyImage
                image={sanityPressArticlePage?.magazine?.asset?.gatsbyImageData}
                alt={sanityPressArticlePage?.magazine?.alt}
              />
            </FadeInSection>
          )}
        </Container>
      </section>
      {edges?.length > 1 && (
        <section className="pressArt-container">
          <Container className="pa-buttons">{renderPrevNext()}</Container>
        </section>
      )}
      <section className="reach-out">
        <Container>
          <FadeInSection timeout={250}>
            <h3>Interested in featuring Tyler Karu?</h3>
            <ButtonComponent
              button={{
                type: 'primary',
                title: 'REACH OUT TO US HERE',
                slug: { current: '/contact' },
              }}
            />
          </FadeInSection>
        </Container>
      </section>
    </>
  )
}

export default PressArticlePage

export const pageQuery = graphql`
  query sanityPressArticlePage($slug: String) {
    sanityPressArticlePage(slug: { current: { eq: $slug } }) {
      title
      magazine {
        alt
        asset {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            width: 300
          )
        }
      }
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      _rawSubtitle(resolveReferences: { maxDepth: 10 })
      _rawContent(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
      image {
        alt
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
    allSanityPressArticlePage {
      edges {
        node {
          slug {
            current
          }
          title
        }
      }
    }
  }
`
